// consumer_web_policyConfig_updateCityTemplate
const __request = require(`./__request/__request_contentType_json`);
const consumer_web_policyConfig_updateCityTemplate = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/consumer/web/policyConfig/updateCityTemplate',
    data: pParameter,
    enable_error_alert: false // 临时隐藏
  };
  return new Promise(function(resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_web_policyConfig_updateCityTemplate;
